import React, { Suspense, lazy } from 'react';
import { withApollo, Query } from 'react-apollo';
import Header from './common/ui/topbar/header';
import Menu from './common/ui/navigation';
import Footer from './common/ui/footer/footer';
import { renderNestedQueries, getNextRenderer } from "./common/utils/renderer";
import { ROLES } from './common/auth/guard/roles';
import { checkAccessGuard } from './common/auth/guard/check';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { getPropertiesFilterShape } from "./properties/list/cache";
import USER_GQL from './common/auth/user.graphql';
import Loading from './common/ui/loading';
import { KnowledgeBaseApolloClientProvider } from './context/knowledge-base-apollo-client-context';
import './common/ui/semantic-ui/style.scss';

/**
 * Waits for user' roles to be available after sign-in
 */
const waitForUserProfile = (args, prevData, ...next) => {
    return <Query query={USER_GQL}>
        {
            ({ data: { user: { roles, assignedCities } } }) => {
                if (roles === null || assignedCities === null) {
                    return null;
                }
                return (
                    getNextRenderer(args, { roles, assignedCities }, ...next)
                )
            }
        }
    </Query>;
};
/**
 * Sets default city based on assigned cities to logged-in account
 */
const setDefaultCitySelection = (args, prevData, ...next) => {
    if (prevData.assignedCities && prevData.assignedCities.length) {
        args.client.cache.writeData(getPropertiesFilterShape({ city: prevData.assignedCities[0] }));
    }
    return getNextRenderer(args, prevData, ...next);
};
/**
 * Home route based on assigned roles
 */
const setHomeComponent = (args, prevData, ...next) => {
    let homeComponent = null;
    if (checkAccessGuard(args.client, ROLES.PROPERTIES_MENU)) {
        homeComponent = <Route path="/" component={lazy(() => import('./properties'))} />;
    }
    else if (checkAccessGuard(args.client, ROLES.MAINTENANCE_MENU)) {
        homeComponent = <Route path="/" component={lazy(() => import('./maintenance/case-categories'))} />;
    }
    return getNextRenderer(args, { ...prevData, homeComponent }, ...next);
};
/**
 * Routes
 */
const buildRouter = (args, { homeComponent }) => {
    // if on the floating page built for front-desk booking, disable the default router
    const frontDeskBookingUrl = '/front-desk-booking';

    return (
        <KnowledgeBaseApolloClientProvider>
            <Suspense fallback={<Loading />}>
                <Router>
                    <Switch>
                        <Route path={frontDeskBookingUrl} component={lazy(() => import('./properties/events/front-desk'))} />
                    </Switch>
                </Router>
            </Suspense>
            {
                !window.location.href.endsWith(frontDeskBookingUrl) &&
                <div id="main-wrapper">
                    <Suspense fallback={<Loading/>}>
                        <Router>
                            <Header/>
                            <Menu/>
                            <div className="page-wrapper" style={{marginBottom: '100px'}}>
                                <Switch>
                                    <Route path="/property/management/new-edit/:id"
                                           component={lazy(() => import('./properties/management/new-edit'))}/>
                                    <Route exact path="/property/events/new-edit/:id"
                                           component={lazy(() => import ('./properties/events/management/new-booking'))}/>
                                    <Route exact path="/property/events/new-edit/:type/:id"
                                           component={lazy(() => import ('./properties/events/management/new-booking'))}/>
                                    <Route path="/property/events/quick-edit/:id"
                                           component={lazy(() => import('./properties/events/management/quick-edit'))}/>
                                    <Route path="/property/events"
                                           component={lazy(() => import('./properties/events/management'))}/>
                                    <Route path="/property/guest-check-list"
                                           component={lazy(() => import('./properties/guest-check-list'))}/>
                                    <Route path="/property/users-management"
                                           component={lazy(() => import('./properties/users-management'))}/>
                                    <Route path="/property/management"
                                           component={lazy(() => import('./properties/management'))}/>
                                    <Route path="/property/ru-import"
                                           component={lazy(() => import('./properties/ru-import'))}/>
                                    <Route path="/property/buildings/new-edit/:id"
                                           component={lazy(() => import('./properties/buildings/new-edit'))}/>
                                    <Route path="/property/buildings"
                                           component={lazy(() => import('./properties/buildings'))}/>
                                    <Route path="/property/landlords/new-edit/:id"
                                           component={lazy(() => import('./properties/landlords/new-edit'))}/>
                                    <Route path="/property/landlords"
                                           component={lazy(() => import('./properties/landlords'))}/>
                                    <Route path="/property/landlords-v2/new-edit/:id"
                                           component={lazy(() => import('./properties/landlords-v2/new-edit'))}/>
                                    <Route path="/property/landlords-v2"
                                           component={lazy(() => import('./properties/landlords-v2'))}/>
                                    <Route path="/property/agents/new-edit/:id"
                                           component={lazy(() => import('./properties/agents/new-edit'))}/>
                                    <Route path="/property/agents"
                                           component={lazy(() => import('./properties/agents'))}/>
                                    <Route path="/revenue/events/new-edit/:isNew"
                                           component={lazy(() => import('./revenue/events/new-edit'))}/>
                                    <Route path="/revenue/events" component={lazy(() => import('./revenue/events'))}/>
                                    <Route path="/revenue/prices/wizard/:propertyID/:year"
                                           component={lazy(() => import('./revenue/prices/wizard'))}/>
                                    <Route path="/revenue/prices/grid"
                                           component={lazy(() => import('./revenue/prices/grid'))}/>
                                    <Route path="/revenue/prices/calendar"
                                           component={lazy(() => import('./revenue/prices/calendar'))}/>
                                    <Route path="/revenue/stats-with-bulk-discounts/wizard/:data"
                                           component={lazy(() => import('./revenue/stats-bulk-discounts/wizard'))}/>
                                    <Route path="/revenue/stats-with-bulk-discounts"
                                           component={lazy(() => import('./revenue/stats-bulk-discounts'))}/>
                                    <Route path="/revenue/update-history"
                                           component={lazy(() => import('./revenue/update-history'))}/>
                                    <Route path="/revenue/summary" component={lazy(() => import('./revenue/summary'))}/>
                                    <Route path="/revenue/los-discounts/"
                                           component={lazy(() => import('./revenue/los-discounts'))}/>
                                    <Route path="/rentals-united/logs"
                                           component={lazy(() => import('./ru/logs-viewer'))}/>
                                    <Route path="/rentals-united/channel-sync"
                                           component={lazy(() => import('./ru/channel-sync'))}/>
                                    <Route path="/analysis/airbnb" component={lazy(() => import('./analysis/airbnb'))}/>
                                    <Route path="/maintenance/case-categories"
                                           component={lazy(() => import('./maintenance/case-categories'))}/>
                                    <Route path="/maintenance/staff"
                                           component={lazy(() => import('./maintenance/staff'))}/>
                                    <Route path="/maintenance/registry/new-edit/:id"
                                           component={lazy(() => import('./maintenance/registry/new-edit'))}/>
                                    <Route path="/maintenance/registry"
                                           component={lazy(() => import('./maintenance/registry'))}/>
                                    <Route path="/maintenance/assignments"
                                           component={lazy(() => import('./maintenance/assignments'))}/>
                                    <Route path="/maintenance/notifications"
                                           component={lazy(() => import('./maintenance/notifications'))}/>
                                    <Route path="/housekeeping/buildings"
                                           component={lazy(() => import('./housekeeping/index'))}/>
                                    <Route path="/housekeeping/events"
                                           component={lazy(() => import('./housekeeping/events'))}/>
                                    <Route path="/housekeeping/timeline"
                                           component={lazy(() => import('./housekeeping/timeline'))}/>
                                    <Route path="/invoicing" component={lazy(() => import('./invoicing'))}/>
                                    <Route path="/management/accounts" component={lazy(() => import('./management'))}/>
                                    <Route path="/contacts/new-edit/:id"
                                           component={lazy(() => import('./contacts/new-edit'))}/>
                                    <Route path="/contacts" component={lazy(() => import('./contacts'))}/>
                                    <Route path="/sales/target" component={lazy(() => import('./sales/target'))}/>
                                    <Route path="/sales/analysis" component={lazy(() => import('./sales/analysis'))}/>
                                    <Route path="/sales/channel-revenue"
                                           component={lazy(() => import('./sales/channel-revenue'))}/>
                                    <Route path="/accounting/bookkeeping"
                                           component={lazy(() => import('./accounting/bookkeeping/main'))}/>
                                    <Route path="/accounting/departments-budgets"
                                           component={lazy(() => import('./accounting/budgets'))}/>
                                    <Route path="/accounting/departments-expenses"
                                           component={lazy(() => import('./accounting/expenses'))}/>
                                    <Route path="/accounting/department-expense-categories"
                                           component={lazy(() => import('./accounting/categories'))}/>
                                    <Route path="/ustella/buildings/new-edit/:id"
                                           component={lazy(() => import('./ustella/buildings/new-edit'))}/>
                                    <Route path="/ustella/buildings"
                                           component={lazy(() => import('./ustella/buildings'))}/>
                                    <Route path="/ustella/landlords/new-edit/:id"
                                           component={lazy(() => import('./ustella/landlords/new-edit'))}/>
                                    <Route path="/ustella/landlords"
                                           component={lazy(() => import('./ustella/landlords'))}/>
                                    <Route path="/ustella/commercial-properties/:id"
                                           component={lazy(() => import('./ustella/properties/commercial'))}/>
                                    <Route path="/ustella/long-term-properties/:id"
                                           component={lazy(() => import('./ustella/properties/long-term'))}/>
                                    <Route path="/ustella/properties"
                                           component={lazy(() => import('./ustella/properties'))}/>
                                    <Route path="/ustella/companies/new-edit/:id"
                                           component={lazy(() => import('./ustella/companies/new-edit'))}/>
                                    <Route path="/ustella/companies"
                                           component={lazy(() => import('./ustella/companies'))}/>
                                    <Route path="/ustella/commercial-leases/new-edit/:id"
                                           component={lazy(() => import('./ustella/commercial-leases/new-edit'))}/>
                                    <Route path="/ustella/commercial-leases"
                                           component={lazy(() => import('./ustella/commercial-leases'))}/>
                                    <Route path="/ustella/tenants/new-edit/:id"
                                           component={lazy(() => import('./ustella/tenants/new-edit'))}/>
                                    <Route path="/ustella/tenants" component={lazy(() => import('./ustella/tenants'))}/>
                                    <Route path="/ustella/long-term-leases/new-edit/:id"
                                           component={lazy(() => import('./ustella/long-term-leases/new-edit'))}/>
                                    <Route path="/ustella/long-term-leases"
                                           component={lazy(() => import('./ustella/long-term-leases'))}/>
                                    <Route path="/ustella/maintenance/new-edit/:id"
                                           component={lazy(() => import('./ustella/maintenance/new-edit'))}/>
                                    <Route path="/ustella/maintenance"
                                           component={lazy(() => import('./ustella/maintenance'))}/>
                                    <Route path="/ustella/expenses/new-edit/:id"
                                           component={lazy(() => import('./ustella/expenses/new-edit'))}/>
                                    <Route path="/ustella/expenses"
                                           component={lazy(() => import('./ustella/expenses'))}/>
                                    <Route path="/ustella/viewing-schedule/new-edit/:id"
                                           component={lazy(() => import('./ustella/viewing-schedule/new-edit'))}/>
                                    <Route path="/ustella/viewing-schedule"
                                           component={lazy(() => import('./ustella/viewing-schedule'))}/>
                                    <Route path="/smart-locks/locks"
                                           component={lazy(() => import('./smart-locks/locks'))}/>
                                    <Route path="/smart-locks/smarthome-365-locks"
                                           component={lazy(() => import('./smart-locks/smarthome-365-locks'))}/>
                                    <Route path="/smart-locks/passcodes"
                                           component={lazy(() => import('./smart-locks/passcodes'))}/>
                                    <Route path="/smart-locks/smarthome-365-passcodes"
                                           component={lazy(() => import('./smart-locks/smarthome-365-passcodes'))}/>
                                    <Route path="/smart-locks/tuya-iot-locks"
                                           component={lazy(() => import('./smart-locks/tuya-iot-locks'))}/>
                                    <Route path="/smart-locks/tuya-iot-passcodes"
                                           component={lazy(() => import('./smart-locks/tuya-iot-passcodes'))}/>
                                    <Route path="/knowledge-base" component={lazy(() => import('./knowledge-base'))}/>
                                    <Route path="/property/campaigns" component={lazy(() => import('./properties/campaigns'))}/>
                                    <Route path="/ge-audits" component={lazy(() => import('./ge-audits'))}/>
                                    {homeComponent}
                                </Switch>
                            </div>
                        </Router>
                    </Suspense>
                    <Footer/>
                </div>
            }
        </KnowledgeBaseApolloClientProvider>
    );
};

const App = ({client}) => {
    return (
        renderNestedQueries(
            {client},
            {},
            waitForUserProfile,
            setDefaultCitySelection,
            setHomeComponent,
            buildRouter
        )
    );
};

export default withApollo(App);